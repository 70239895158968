import axios from 'axios'
import { requestInterceptors, responseInterceptors } from './api'

const gatewayApi = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL
})

gatewayApi.interceptors.response.use(...responseInterceptors)
gatewayApi.interceptors.request.use(...requestInterceptors)

export const fetchTransactionsList = async ({ queryKey, signal, meta }) => {
  const [_key, { currentPage }] = queryKey
  const { filters } = meta
  const data = {
    filter: filters,
    page: currentPage,
    limit: 30,
    include: ['transaction_events']
  }

  return gatewayApi.post('/app/merchant/transactions/search', data, { signal }).then((response) => response.data)
}

export const changeProcessor = async ({ merchantIds, processorType }) => {
  const data = {
    merchant_ids: merchantIds,
    processor_type: processorType
  }

  return gatewayApi.put('/app/admin/precedence/change', data).then((response) => response.data)
}

export const getTransactionsByMerchantId = async ({ queryKey, signal, meta }) => {
  const [_key, { merchant_id, currentPage }] = queryKey
  const { filters } = meta
  const data = {
    page: currentPage,
    filter: filters,
    limit: 30,
    include: ['processor', 'transaction_events', 'statement']
  }

  return gatewayApi
    .post(`/app/admin/merchant/${merchant_id}/transactions`, data, { signal })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const fetchBalanceDashboard = async ({ queryKey }) => {
  const [_key, { todayDate: todayDateStr }] = queryKey

  return gatewayApi
    .get(`/app/merchant/wallet/daily/flow-type-source/v2/${todayDateStr}/${todayDateStr}`)
    .then((response) => response.data)
}

export const fetchPixData = async ({ queryKey }) => {
  const [_key, { startDate, endDate }] = queryKey

  return gatewayApi.get(`app/merchant/dashboard/pix?start_date=${startDate}&end_date=${endDate}`)
}

export const getFlowDailyBalance = async ({ queryKey }) => {
  const [_key, { period }] = queryKey

  const response = gatewayApi
    .get(`/app/merchant/wallet/daily/flow-type-source/v2/${period.startDate}/${period.endDate}`)
    .then((response) => response.data)
  return response
}

export const fetchMerchantBalances = async () => {
  return gatewayApi.get('/app/merchant/balances').then((response) => response.data)
}

export const fetchMerchantBalanceDashboard = async ({ queryKey }) => {
  const [_key, { period }] = queryKey
  return gatewayApi.get(`/app/merchant/wallet/daily/${period.startDate}/${period.endDate}`).then((response) => {
    return response.data
  })
}

export const fetchMerchantProcessors = async ({ queryKey }) => {
  const [_key, { merchantId }] = queryKey

  return gatewayApi
    .get('/app/admin/processors', {
      params: {
        merchant_id: merchantId
      }
    })
    .then((response) => response.data)
}

export const fetchProcessor = async ({ queryKey }) => {
  const [_key, { processorId, userRole }] = queryKey
  const baseUrl = userRole === 'admin' ? '/app/admin/processors' : '/app/merchant/processors'

  return gatewayApi.get(`${baseUrl}/${processorId}`).then((response) => response.data)
}

export const fetchTransaction = async ({ queryKey }) => {
  const [_key, { transactionId, userRole }] = queryKey
  const baseUrl = userRole === 'admin' ? '/app/admin/transactions' : '/app/merchant/transactions'

  return gatewayApi
    .get(`${baseUrl}/${transactionId}?include[]=transaction_events&include[]=statements`, {
      params: {
        include: ['processor', 'transaction_events', 'statement']
      }
    })
    .then((response) => response.data)
}

export const sendVoid = (transactionId) => {
  const adminToken = localStorage.getItem('@App:admin_token')

  return gatewayApi.post(
    `/app/admin/transactions/${transactionId}/void`,
    {},
    {
      headers: {
        Authorization: adminToken.includes('Bearer ') ? adminToken : `Bearer ${adminToken}`
      }
    }
  )
}

export const fetchPriorityProcessor = async (merchantId) => {
  const adminToken = localStorage.getItem('@App:token')

  if (!adminToken) {
    throw new Error('Token de admin não encontrado')
  }

  return gatewayApi
    .get(
      `/app/admin/processors/${merchantId}/precedence`,
      {
        merchant_id: merchantId
      },
      {
        headers: {
          Authorization: adminToken.includes('Bearer ') ? adminToken : `Bearer ${adminToken}`
        }
      }
    )
    .then((response) => response)
}

export const fetchWalletByMerchantId = async (merchantId) => {
  const adminToken = localStorage.getItem('@App:token')

  if (!adminToken) {
    throw new Error('Token de admin não encontrado')
  }

  return await gatewayApi
    .get(`/app/admin/wallet/${merchantId}/balance`, {
      headers: {
        Authorization: `Bearer ${adminToken}`
      }
    })
    .then((response) => response.data)
}
export const fetchFeeTransfer = async ({ data }) => {
  const adminToken = localStorage.getItem('@App:token')

  if (!adminToken) {
    throw new Error('Token de admin não encontrado')
  }

  // 'api/pix/pay_tax'
  const response = gatewayApi
    .post('app/admin/pay_tax', data, {
      headers: {
        Authorization: adminToken.includes('Bearer ') ? adminToken : `Bearer ${adminToken}`
      }
    })
    .then((response) => response.data)

  return response
}

export const fetchTaxTransaction = async ({ queryKey, meta, period }) => {
  const [_key, { merchant_id, currentPage }] = queryKey
  const { filters } = meta

  const adminToken = localStorage.getItem('@App:token')

  if (!adminToken) {
    throw new Error('Token de admin não encontrado')
  }

  if (!merchant_id && !period) {
    return {
      consolidated_list_fee: [
        {
          fee: {
            merchant: {
              id: merchant_id ?? '',
              name: ' - '
            },
            amount: ' 0 ',
            date_window: {
              start_date: period?.period.startDate ?? '0000-00-00 00:00:00',
              end_date: period?.period.endDate ?? '0000-00-00 00:00:00'
            }
          }
        }
      ],
      fee_sum: '0.0',
      pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 1
      }
    }
  }

  return gatewayApi
    .get(`/app/admin/tax-transactions-list`, {
      headers: {
        Authorization: adminToken.includes('Bearer ') ? adminToken : `Bearer ${adminToken}`
      },
      params: {
        merchant_id: merchant_id,
        limit: 10,
        page: currentPage,
        taxChargeWindow: 'taxChargeWindow',
        start_date: period.period.startDate + ' 00:00:00',
        end_date: period.period.endDate + ' 23:59:59'
      }
    })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.error(error)
    })
}

export default gatewayApi
